// MIXIN FOR BREAKPOINT
// USE: @include for-size(sm) {
//      font-size: 1rem;
//   }

@mixin for-size($size) {
  @if $size == sm {
    @media (max-width: 960px) {
      @content;
    }
  }

  @if $size == mobi {
    @media (max-width: 599px) {
      @content;
    }
  }
}

$gray_lt: #c0bfbf;
$gray_md: #999;
$gray_dk: #333;
$white: #FFF;

$ham_bg_color: #242323;
$ham_bg_color_light: lighten($ham_bg_color, 20%);
$ham_bg_color_lighter: lighten($ham_bg_color, 30%);

//$font_open_sans: 'Open Sans';
$font_open_sans: 'Saira Condensed';
$font_saira_condensed: 'Saira Condensed';
$font_saira_x_condensed: 'Saira Extra Condensed';
$font_saira_x_condensed: 'Saira Extra Condensed';

body {
  width: 96%;
  max-width: 1600px;
  margin: 20px auto;
  color: #fff;
  position: relative;
  //background: $white (darken);
  background: $ham_bg_color;

  @include for-size(sm) {
    padding: 0 10px;
  }

  @include for-size(mobi) {
    width: 92%;
  }
}

body, p, label, form input, form textarea {
  font-family: $font_saira_condensed, sans-serif;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font_saira_x_condensed, sans-serif;
  font-weight: 500;
  padding: 0;
  margin: 0;
  height: auto;
  vertical-align: top;
  text-transform: uppercase;
}

main h1 {
  font-weight: 200;
  padding-top: 20px
}

a {
  text-decoration: none;
  width: 100%;
  color: $white;
  cursor: pointer;

  &:link, &:visited {
    color: $white;
  }

  &:hover {
    color: $gray_md;
  }
}

img {
  border: none;
  width: 100%;
}

p {
  font-size: 18px;
  line-height: 150%;
  font-weight: 200;
}

.overlaydim {
  position: absolute;
  top: 54px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: .9;
  z-index: 2;
}

div#header {
  padding-bottom: 24px;
}

div.name, div.name h1 {
  font-size: 42px;
  font-weight: 500;
  color: #FFF;
  font-family: $font_saira_x_condensed;
  text-transform: uppercase;
  align-self: center;

  @include for-size(sm) {
    font-size: 36px;
    font-weight: 400;
  }
}

/////////////////////
// NAVIGATION ///////
/////////////////////

/* Wrapper */
#hamnav {
  background: $ham_bg_color;
  z-index: 20;
  display: flex;
}

/* Hide Hamburger */
#hamnav label, #hamburger {
  display: none;
}

/* Horizontal Menu Items */
#hamitems {
  display: flex;
  z-index: 20;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    line-height: 1.5em;
    align-self: center;

    li {
      display: inline-block;
      text-decoration: none;
      padding: 0 0 0 31px;
      white-space: nowrap;
      text-transform: uppercase;
      font-family: $font_saira_x_condensed;

      a {
        //width: 20%; /* 100% / 5 tabs = 20% */
        color: $gray_lt;
        font-size: 24px;
        font-weight: 400;
        display: block;
        text-decoration: none;
        padding: 10px 0;

        &.active {
          color: $white;
        }

        &:hover {
          color: $white;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {

  /* Show Hamburger Icon */
  #hamnav {

    label {
      display: block;
      color: $white;
      font-style: normal;
      font-size: 1.7em;
      padding: 15px 0 0;
      order: 2;
      line-height: 0.6;
      cursor: pointer;
      margin-right: -1px;


      &.close_background {
        //background-color: $ham_bg_color;
        //color: $gray_md;
        padding: 20px 3px 0 0;
        font-size: 1.4em;
      }
    }

    input:checked ~ #hamitems {
      display: block;
      position: absolute;
      top: 64px;
      left: 50%;
      width: 96%;
      //background: $ham_bg_color;
      transform: translate(-50%);
    }
  }

  /* Break down menu items into vertical */
  #hamitems {
    display: none;

    ul {
      li {
        display: block;
        text-align: center;
        padding: 0;

        a {
          box-sizing: border-box;
          display: block;
          width: 100%;
          border-top: 1px solid $ham_bg_color_lighter;
          color: #FFF;
          padding: 30px 0;

          &.active {
            color: #fff;
            background: $ham_bg_color_light;

            &:hover {
              background: $ham_bg_color_light;
            }
          }

          &:hover {
            background: $ham_bg_color_lighter;
            color: #fff;
          }
        }
      }
    }
  }
}

/////////////////////
// END NAVIGATION
/////////////////////

main {
  margin: 0;
  padding-top: 0;
  clear: both;
}

/////////////////////
/* PORTFOLIO INDEX */
/////////////////////

h2.portfolio_title {
  font-size: 26px;
  font-weight: 200;
}

#thumbs_details_container {
  padding: 0 0 40px;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;

  div.thumbs {
    //display: inline-flex;
    width: 48%;
    //height: auto;
    //max-height: 400px;
    text-align: center;
    font-size: 14px;
    margin: 12px 0 20px;
    text-decoration: none;
    position: relative;
    clear: both;
    //overflow: hidden;
    //justify-content: space-between;

    @include for-size(mobi) {
      width: 100%;
    }

  }

  a {
    text-decoration: none;
    //position: relative;

    &:hover {
      span {
        text-decoration: none;
      }
    }
  }

  img {
    .thumb {
      margin-bottom: 5px;
      padding: 0;
      //border: 4px double #BFBCBC;
      width: 100%;
      height: 100%;
      //object-fit: contain;

      &:hover {
        border: 4px double #3B86BD;
      }
    }
  }
}

/* PORTFOLIO ALBUM DETAILS */

.album_sub_row {
  width: 100%;
  overflow: hidden;
  padding-bottom: 15px;
  clear: both;
  display: block;
}

.album_subheader {
  max-width: 350px;
  float: left
}

.album_submenu {
  max-width: 500px;
  text-align: center;
  position: relative;
  margin: 10px 0;
  padding: 0;
  float: right;


  ul {
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    line-height: 1.5em;
    width: 88%;

    li {
      float: left;
      position: relative;
      padding: 0;
      margin: 0;
      display: inline;

      a {
        color: $gray_md;
        font-size: 14px;
        display: block;
        text-decoration: none;
        padding: 0 20px;

        &:hover {
          color: #333;
        }
      }
    }
  }
}


#mainItemdiv {
  width: 100%;
  max-width: 500px;
  float: right;
  margin: 0;
  padding-bottom: 20px;
  text-align: center;

  img {
    #mainItem {
      margin: 0 0 4px 0;
    }
  }
}

#caption {
  text-align: center;
}

#album_thumbs_wrapper {
  float: left;
  max-width: 350px;
  padding: 0;
  margin: 0;

  div {
    padding-bottom: 8px;
  }
}

#album_thumbs {
  max-width: 350px;
  margin: 0;

  img {
    float: left;
    margin: 0 5px 5px 0;
    padding: 0;
    max-width: 108px;
    display: inline !important
  }

}

/* Contact */

#contact {
  div.image {
    width: 45%;
    display: inline-block;
    vertical-align: top;
    margin-top: 24px;

    @include for-size(sm) {
      display: block;
      margin: 30px auto;
      width: 100%;
      max-width: 500px;
    }
  }

  div#request_form {
    width: 49%;
    //display: inline-block;
    margin: auto;

    @include for-size(sm) {
      width: 100%;
      display: block;
      margin: auto;
    }
  }

  form {
    margin: 30px auto 0 auto;
    font-size: 20px;
    max-width: 440px;
    width: 100%;

    @include for-size(sm) {
      width: 100%;
      display: block;
      margin: 20px auto;
    }

    ul {
      list-style: none;
      margin: 0 auto;
      padding: 0;

      li {

        margin: 0 0 15px 0;

        label {
          display: none;
        }

        ul {
          &.errorlist {

            li {
              margin: 0 0 5px 0;

            }
          }
        }

        input,
        textarea {
          border: 1px solid #ddd;
          //max-width: 340px;
          padding: 10px;
          font-size: 20px;
          width: 100%;
          box-sizing: border-box;

          &::placeholder {
            color: #aaa;
          }
        }


        div.g-recaptcha {
          @include for-size(sm) {

            > div {
              margin: auto;
            }
          }
        }
      }
    }

    p {
      @include for-size(sm) {
        text-align: center;

      }
    }

    input {

      &.submit {
        background-color: #eee;
        width: 100%;
        padding: 15px;
        font-size: 20px;
        //margin-right: 12px;
        color: #333;
        border: none;

        @include for-size(sm) {

        }

        &:hover {
          background: $gray_md;
          color: #FFF;
        }

        &:active {
          color: #333
        }
      }
    }
  }

}

/* COLORS */

.c_gray {
  color: $gray_md;

  a {
    &:link,
    &:visited {
      color: $gray_md
    }

    &:hover,
    &:active {
      color: $gray_dk
    }
  }
}

.c_graydk {
  color: $gray_dk;

  a {
    &:link,
    &:visited {
      color: $gray_dk
    }

    &:hover,
    &:active {
      color: $gray_md
    }
  }
}


.home_pic {
  margin: 60px auto 0px;
  text-align: center;
  max-width: 1000px;
}

.home_artist {
  padding: 70px 0 0 0;
  text-align: center;
  font-size: 20px;
  //letter-spacing: 10px;


  li {
    display: inline-block;
    padding: 10px 60px;
    margin: auto;

  }
}

.home_about {
  margin: 120px auto;
  width: 390px;
}

.f11 {
  font-size: 11px
}

.f29 {
  font-size: 29px
}

.fleft {
  float: left
}

.fright {
  float: right
}

.overflow {
  overflow: hidden;
}

.letter-spacing-wide {
  letter-spacing: 2px;
}

.text_center {
  text-align: center;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

#lightgallery a {
  cursor: pointer
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 100%);
  grid-gap: 1.7rem;
  padding-top: 10px;
  justify-content: space-evenly;
}

//.lg-outer .lg-thumb-item {
//  -webkit-transition: border-color .25s ease;
//  -o-transition: border-color .25s ease;
//  transition: border-color .25s ease;
//}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border: 2px solid #FFF;
}

.lg-container .lg-sub-html {
  font-family: $font_saira_condensed;
  font-size: 1.1em;
}

@media only screen and (min-width: 600px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fill, 48%);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }
  .lg-container .lg-sub-html {
    font-size: 1.3em;
  }

  .lg-outer .lg-thumb-item {
    border-radius: 50%;
    border: 1px solid #777;
  }
}

@media only screen and (min-width: 1024px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fill, 32%);
    grid-column-gap: 10px;
    grid-row-gap: 16px;
  }
}